<template>
  <div>
    <b-card>
      <h6>
        {{ `${$t('fields.result')} (${total})` }}
      </h6>
      <b-row
        align-v="center"
        class="my-2"
      >
        <b-col>
          <b-input-group>
            <b-form-input
              v-model="search"
              type="search"
              :placeholder="`${$t('fields.search')}...`"
            />
          </b-input-group>
        </b-col>
        <b-col
          cols="12"
          md="8"
        >
          <b-input-group>
            <date-time-picker
              @update="(date) => selectedFromDate = date"
            />
            <b-input-group-text>
              ถึง
            </b-input-group-text>
            <date-time-picker 
              @update="(date) => selectedToDate = date"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row
        align-h="center"
      >
        <b-col cols="4">
          <b-select v-model="selectedType">
            <b-form-select-option value="">
              เลือกประเภท
            </b-form-select-option>

            <b-form-select-option
              v-for="(type, typeI) in typeOptions"
              :key="typeI"
              :value="type.value"
            >
              {{ type.name }}
            </b-form-select-option>
          </b-select>
        </b-col>
        <b-col
          v-if="isOwner"
          cols="4"
        >
          <master-select-input 
            :value="selectedMaster"
            hide-label
            @update="onMasterIdChange"
          />
        </b-col>
        <b-col cols="4">
          <agent-select-input
            :value="selectedAgent"
            :master-id="selectedMaster"
            hide-label
            @update="onAgentIdChange"
          />
        </b-col>
      </b-row>
      <div class="text-right mb-2">
        <b-button
          variant="success"
          @click="fetchData"
        >
          <feather-icon icon="SearchIcon" />
          ค้นหา
        </b-button>
      </div>
      <!-- Table -->
      <b-table
        :items="transactions"
        :fields="fields"
        :busy="isLoading"
        responsive
        show-empty
      >
        <template #cell(createdAt)="data">
          {{ data.value | datetime }}
        </template>
        <template #cell(user)="data">
          {{ data.value.username || '-' }}
        </template>
        <template #cell(creator)="data">
          {{ data.value.username || '-' }}
        </template>
        <template #cell(amount)="data">
          <span :class="`text-${displayCreditTypeColor(data.item.type)}`">
            <span v-if="displayCreditTypeColor(data.item.type) === 'success'">
              +
            </span>
            <span v-else-if="displayCreditTypeColor(data.item.type) === 'danger'">
              -
            </span>
            {{ data.value | currency }}
          </span>
        </template>
        <template #cell(beforeAmount)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(afterAmount)="data">
          {{ data.value | currency }}
        </template>
        <template #cell(type)="data">
          <b-badge :variant="displayCreditTypeColor(data.value)">
            {{ displayCreditType(data.value) }}
          </b-badge>
        </template>
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template #empty="">
          <p class="text-center text-muted">
            {{
              $t('messages.nothing_here')
            }}
          </p>
        </template>
      </b-table>
      <!-- pagination -->
      <b-row>
        <b-col md="4">
          <page-limit-select
            :value="limit"
            @update="onLimitChange"
          />
        </b-col>
        <b-col>
          <pagination-input
            :per-page="limit"
            :total="total"
            @update="(val) => currentPage = val"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  page: {
    title: 'All Credit Transactions',
  },
  data() {
    return {
      search: '',
      onSearchTimeout: null,
      selectedMaster: '',
      selectedAgent: '',
      selectedFromDate: '',
      selectedToDate: '',
      selectedType: '',
      typeOptions: [
        { name: 'ทั้งหมด', value: 'ALL' },
        { name: 'โอนเงิน', value: 'TRANSFER' },
        { name: 'เดิมพัน', value: 'BETTING' },
      ],
      currentPage: 1,
      selectedLimit: 20,

      fields: [
        {
          key: 'createdAt',
          label: this.$t('fields.issued_date'),
          thStyle: {
            minWidth: '160px',
          },
        },
        {
          key: 'user',
          label: `${this.$t('fields.user_phone_number')}`,
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'type',
          label: this.$t('credits.type'),
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'beforeAmount',
          label: this.$t('credits.before_amount'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'amount',
          label: this.$t('credits.credit_amount'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'afterAmount',
          label: this.$t('credits.remain_credits'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
         {
          key: 'creator',
          label: 'ผู้กระทำ',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'detail',
          label: `${this.$t('fields.remark')}`,
          thStyle: {
            minWidth: '250px',
          },
        },
      ],
      masterCredits: {
        data: [],
        total: 0,
      },
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.credit.isFetchingPlayersCreditHistory,
    }),
    ...mapGetters(['isOwner','playersCreditHistory']),
    transactions() {
      return this.playersCreditHistory.items || []
    },
    pagination() {
      return this.playersCreditHistory.meta
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
    querySearch() {
      return this.$route.query.search || ''
    },
  },
  watch: {
    currentPage(val) {
      if(val){
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    search() {
      this.delaySearch()
    },
    selectedType(val) {
      if(val){
        this.fetchData()
      }
    },
    selectedDate() {
      this.fetchData()
    },
  },
  created() {
    if(this.querySearch){
      this.search = this.querySearch
    }
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchPlayersCreditHistory']),
    fetchData() {
      this.fetchPlayersCreditHistory({
        limit: this.selectedLimit,
        page: this.currentPage,
        search: this.search,
        agentId: this.selectedAgent, 
        masterId: this.selectedMaster,
        type: this.selectedType,
        from: this.selectedFromDate || '',
        to: this.selectedToDate || '',
      })
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onLimitChange(limit) {
      if(limit){
        this.selectedLimit = limit
        this.fetchData()
      }
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.fetchData()
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.fetchData()
    },
    displayCreditType(type) {
      switch (`${type}`) {
        case '0':
          return 'ฝาก (+)'
        case '1':
          return 'ถอน (-)'
        case '2':
          return 'ไม่อนุมัติถอนเงิน (+)'
        case '3':
          return 'ฝากตรง (+)'
        case '4':
          return 'ถอนตรง (-)'
        case '5':
          return 'โบนัส (+)'
        case '6':
          return 'แลกค่าแนะนำ (+)'
        case '7':
          return 'แลกแคชแบค (+)'
        case '8':
          return 'โยกกระเป๋าเงิน (+)'
        case '9':
          return 'เดิมพัน (-)'
        case '10':
          return 'ชนะเดิมพัน (+)'
        case '11':
          return 'ชนะโบนัส (+)'
        case '12':
          return 'ยกเลิกเดิมพัน (+)'
        case '13':
          return 'เดิมพันโมฆะ (+)'
        default:
          return `${type}`;
      }
    },
    displayCreditTypeColor(type) {
      switch (`${type}`) {
        case '0':
        case '2':
        case '3':
        case '5':
        case '6':
        case '7':
        case '8':
        case '10':
        case '11':
        case '12':
        case '13':
          return 'success'
        case '1':
        case '4':
        case '9':
          return 'danger'
        default:
          return 'light';
      }
    },
  },
}
</script>
